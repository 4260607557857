import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import {
    getFunctions,
    getPosition,
    consultPosition
} from '../services/services';
import { Precios, Map, IntroWithTitle, Notas } from '../components';
import MapaC from './mapa';
import MapaV from './mapa/mapaV';
import WaitingIn from './cola/waitingIn';
// import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
// import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import '../styles/App.sass';
import { useLocation, useNavigate } from 'react-router-dom';

moment.locale('es');

const values = [
    {
        id: 'academia',
        image: `${process.env.REACT_APP_URL_IMAGES}academia.jpg`,
        name: 'Academia 318',
        title: 'Clínicas 318 Performance',
        isDisable: false,
        sinopsis: [
            '¡Una emocionante experiencia de 5 días diseñada especialmente para niños y jóvenes de 6 a 17 años!',
            'Durante estos días, los participantes tendrán la oportunidad de aprender nuevas técnicas, explorar su potencial y disfrutar de actividades emocionantes que los dejarán con recuerdos inolvidables. Además, ¡es el primer campamento especializado en formar vinotintos, creado por vinotintos!',
            'No solo nos enfocamos en el campo de juego, sino que también ofrecemos la posibilidad de integrar la tecnología para mejorar la experiencia de nuestros participantes. Con el uso de tecnología innovadora en sus uniformes, como la marca de GPS Oliver, podremos rastrear y analizar las estadísticas de su desempeño, brindándoles una visión más completa de su progreso y habilidades.',
            'Al finalizar el programa, cada participante recibirá un diploma de asistencia avalado por la FVF, reconociendo su compromiso y dedicación durante estos días de aprendizaje y diversión. ¡No te pierdas esta oportunidad única de ser parte de nuestra comunidad y vivir la pasión por el deporte junto a nosotros!'
        ]
    },
    {
        id: 'arango',
        image: '/images/arango.png',
        name: 'Despedida de Juan Arango',
        title: 'Sports Capital - Despedida de Juan Arango',
        isDisable: true,
        sinopsis: [
            'Academia 2',
            'Academia de formación de futuros titanes',
            'Consiste en reunir durante 5 días a 400 niños y jóvenes entre 6 a 16 años por ciudad. Objetivos: Aprender técnicas nuevas, que se diviertan y queden impregnados del Sentimiento Vinotinto. Incluso se quiere: Descubrir nuevos talentos. Utilizar tecnología en sus uniformes para conocer las estadísticas de su desempeño con la marca de GPS Oliver. Certificar con diploma de asistencia avalado por la FVF.'
        ]
    }
];

const FullFunctions = ({ moneda, setMoneda, monedas, allowBuy }) => {
    const [allFunctions, setAllFunctions] = useState([]);
    const [selectedSite, setSelectedSite] = useState();
    const [funciones, setFunciones] = useState([]);
    const [Verificando, setVerificando] = useState(false);
    const [funcionSel, setFuncionSel] = useState(null);
    const [loading, setLoading] = useState(true);
    const [dataSIM, setDataSIM] = useState(null);
    const timer = React.useRef();
    const cola = true;
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        handleFunciones();
    }, []);

    useEffect(() => {
        if ((!cola || (dataSIM && dataSIM.is_allowed)) && funcionSel !== null) {
            if (funcionSel.tipo === 'boton') {
                if (
                    funcionSel.entradas[moneda] &&
                    funcionSel.entradas[moneda].trim() !== ''
                ) {
                    document.location.href = cola
                        ? `${funcionSel.entradas[moneda]}/?host=${
                            dataSIM.host || 0
                        }`
                        : `${funcionSel.entradas[moneda]}/`;
                    setFuncionSel(null);
                    setDataSIM(null);
                }
            } else {
                console.log(funcionSel);
            }
        }
    }, [dataSIM, funcionSel]);

    const handleFunciones = async () => {
        const data = await getFunctions();
        if (data) {
            const finalData = data.map((f) => {
                f.verMapa = f.verMapa ? f.verMapa : data.length === 1;
                return f;
            });
            console.log('finalData', finalData);
            setAllFunctions(finalData);
        }
        setLoading(false);
    };

    useEffect(() => {
        // console.log("allFunctions", allFunctions)
        // console.log('location', location)
        // if (allFunctions.length > 0) {
        // console.log("actividad", allFunctions[0].actividad)
        // setSelectedSite(allFunctions[0].actividad)
        // }
        if (allFunctions.length > 0) {
            if (location.pathname === '/academia') {
                setSelectedSite('academia');
            }
            if (location.pathname === '/despedida') {
                setSelectedSite('arango');
            }
            if (location.pathname === '/conversatorio') {
                setSelectedSite('liga');
            }
        }
    }, [allFunctions, location]);

    const handleClick = async (f) => {
        setFuncionSel(f);
        if (!dataSIM && cola) {
            setVerificando(true);
            const data = await getPosition(uuidv4(), f.id);
            if (data) {
                setDataSIM({
                    ...data,
                    clientDate: new Date().getTime()
                });
                if (!data.is_allowed) {
                    timer.current = setInterval(async () => {
                        refreshInfo(data.host);
                    }, data.refresh_in_milliseconds);
                }
            } else {
                setDataSIM({ is_allowed: true });
            }
        }
    };
    const refreshInfo = async (host) => {
        let aux = await consultPosition(host);
        console.log(aux);
        if (aux) {
            setDataSIM({
                ...aux,
                clientDate: new Date().getTime()
            });
            if (aux.is_allowed) {
                clearInterval(timer.current);
            }
        }
    };

    const getEstatus = (func) => {
        let classCss = '';
        let textBtn = 'Tickets';
        if (func.tipo === 'boton') {
            classCss =
                !func.entradas[moneda] ||
                func.entradas[moneda] === '' ||
                func.entradas[moneda].trim() === '' ||
                func.entradas[moneda].trim() === 'agotado'
                    ? 'agotado'
                    : '';
            textBtn =
                !func.entradas[moneda] ||
                func.entradas[moneda] === '' ||
                func.entradas[moneda].trim() === '' ||
                !allowBuy
                    ? func.textProx
                        ? func.textProx
                        : 'PROXIMAMENTE'
                    : func.entradas[moneda].trim() === 'agotado'
                        ? func.textAgo
                            ? func.textAgo
                            : 'AGOTADO'
                        : func.textTk
                            ? func.textTk
                            : 'INSCRIPCIÓN';
        } else {
            classCss =
                func.entradas[moneda] &&
                typeof func.entradas[moneda] === 'object' &&
                Object.keys(func.entradas[moneda]).filter(
                    (n) =>
                        func.entradas[moneda][n].trim() !== 'agotado' &&
                        func.entradas[moneda][n].trim() !== ''
                ).length === 0
                    ? 'agotado'
                    : '';
            textBtn =
                func.entradas[moneda] &&
                typeof func.entradas[moneda] === 'object' &&
                Object.keys(func.entradas[moneda]).filter(
                    (n) => func.entradas[moneda][n].trim() !== 'agotado'
                ).length > 0
                    ? func.entradas[moneda] &&
                      typeof func.entradas[moneda] === 'object' &&
                      Object.keys(func.entradas[moneda]).filter(
                          (n) => func.entradas[moneda][n].trim() !== ''
                      ).length > 0 &&
                      allowBuy
                        ? 'INSCRIPCIÓN'
                        : 'PROXIMAMENTE'
                    : 'AGOTADO';
        }
        return { classCss, textBtn };
    };

    useEffect(() => {
        const filteredFunctions = allFunctions.filter(
            (fun) => fun.actividad === selectedSite
        );
        console.log('filteredFucntions', filteredFunctions);
        setFunciones(filteredFunctions);
    }, [selectedSite]);

    // const pagination = {
    //     clickable: true,
    //     renderBullet: function (_, className) {
    //         return '<span class="' + className + ' buttonsGallery"></span>';
    //     }
    // };
    // let slideView = 3;
    // if (window.innerWidth > 500 && window.innerWidth < 768) {
    //     slideView = 3;
    // }
    // if (window.innerWidth < 500) {
    //     slideView = 3;
    // }

    const goToElement = (elementName) => {
        const element = document.getElementById(
            elementName.replaceAll(' ', '')?.toLowerCase()
        );

        if (element?.offsetTop !== undefined) {
            window.scrollTo({
                top: element.offsetTop - -500,
                behavior: 'smooth'
            });
        }
    };

    return loading ? (
        <div></div>
    ) : (
        <>
            <section className="ld--wrap-fullfunc mb-4" id="cities">
                <div className="ld--card">
                    {/* <Swiper
                        slidesPerView={slideView}
                        pagination={pagination}
                        freeMode={true}
                        centerInsufficientSlides
                        spaceBetween={50}
                        navigation={true}
                        autoplay={{ delay: 5000, disableOnInteraction: true }}
                        modules={[Autoplay, Pagination, Navigation]}>
                        {values.map((site) => (
                            <SwiperSlide key={site.id}>
                                <div
                                    className={site.id === selectedSite ? "ld--funcard-selected" : "ld--funcard"}
                                    onClick={() => {
                                        if (site.isDisable) return
                                        setSelectedSite(site.id)
                                        goToElement("cities");
                                        const redirectTo = {
                                            academia: "/academia",
                                            arango: "/despedida",
                                            liga: "/conversatorio",
                                        }
                                        navigate(redirectTo[site.id])
                                    }}
                                >
                                    <img src={site.image} alt={site.id} />
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper> */}
                    {values.map((site) => (
                        <div
                            key={site.id}
                            className={
                                site.id === selectedSite
                                    ? 'ld--funcard-selected'
                                    : 'ld--funcard'
                            }
                            onClick={() => {
                                if (site.isDisable) return;
                                setSelectedSite(site.id);
                                goToElement('cities');
                                const redirectTo = {
                                    academia: '/academia',
                                    arango: '/despedida',
                                    liga: '/conversatorio'
                                };
                                navigate(redirectTo[site.id]);
                            }}
                        >
                            <img src={site.image} alt={site.id} />
                        </div>
                    ))}
                </div>
            </section>

            <div className="row">
                <div className="col-12 col-xl-6 d-flex mb-4">
                    <IntroWithTitle
                        title={
                            values.find((item) => item.id === selectedSite)
                                ?.title
                        }
                        intro={
                            <>
                                {values
                                    .find((item) => item.id === selectedSite)
                                    ?.sinopsis?.map((text) => (
                                        <p key={text}>{text}</p>
                                    ))}
                            </>
                        }
                    />
                </div>
                <div className="col-12 col-xl-6 d-flex mb-4">
                    <Notas
                        notas={[
                            '5 Días por Ciudad, 2.5 horas de campamento intensivo diario',
                            'El paquete base del campamento incluye: 1 Uniforme (camisa y short), Hidratación y refrigerios (fruta, barritas)',
                            'ADICIONAL (a la venta): Uniformes adicionales, GPS Oliver: OLIVER es un mini GPS con ciencia deportiva y una app integrada que ofrece información vital para mejorar el juego de los atletas, evitar lesiones y medir su rendimiento. Permite comparar métricas con profesionales y jugadores del mismo nivel, recibe recomendaciones para progresar y participa en desafíos y rankings para ganar premios, retar a amigos y otros usuarios de OLIVER.'
                        ]}
                    />
                </div>
            </div>

            <section className="ld--wrap-funciones">
                <div className="ld--card">
                    <div className="ld--wrap-botones-titles d-flex justify-content-between">
                        <h1 className="ld--title">Funciones</h1>
                        {monedas.length > 1 && (
                            <div className="ld--monedas">
                                {monedas.map((m) => (
                                    <span
                                        key={m}
                                        className={moneda === m ? 'active' : ''}
                                        onClick={() => setMoneda(m)}
                                    >
                                        <span>
                                            Pagar en {m === 'VES' ? 'VEF' : m}
                                        </span>
                                    </span>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className="ld--funciones">
                        {/* <div className="ld--card mb-4">
                            <div className="ld--item-funcion">
                                <div className="ld--equipos">
                                    <img style={{ maxWidth: 'initial' }} src={`${process.env.REACT_APP_URL_IMAGES_COMMONS}avanti-logo.png`} alt="avanti-logo" />
                                </div>
                                <div className="ld--lugar">
                                    <b>Preventa Avanti</b>
                                    <p>Preventa solo clientes avanti,<b> disponibles 30 y 31 de octubre</b></p>
                                </div>
                                <div className="ld--acciones">
                                    <button className="ld--btn  disabled" disabled=""><span>PROXIMAMENTE</span></button>
                                </div>
                            </div>
                        </div> */}
                        {funciones.map((f, idx) => {
                            const { classCss, textBtn } = getEstatus(f);
                            let ending = moment(
                                moment(f.fecha, 'DD/MM/YYYY h:mm A').format(
                                    'YYYY-MM-DD'
                                )
                            ).isBefore(moment().format('YYYY-MM-DD'));
                            return (
                                !ending && (
                                    <div className="ld--card" key={idx}>
                                        <div className="ld--item-funcion">
                                            <div className="ld--equipos">
                                                <span className="ld--ciudad">
                                                    {f.ciudad}
                                                </span>
                                            </div>
                                            <div className="ld--lugar">
                                                <span className="ld--recinto">
                                                    {f.recinto}
                                                </span>
                                                <span className="ld--fecha">
                                                    {`${moment(
                                                        f.fecha,
                                                        'DD/MM/YYYY hh:mmA'
                                                    ).format(
                                                        'DD/MM/YYYY'
                                                    )} al ${moment(
                                                        f.fechaFin,
                                                        'DD/MM/YYYY hh:mmA'
                                                    ).format('DD/MM/YYYY')}`}
                                                </span>
                                                <span className="ld--fecha">
                                                    {`${moment(
                                                        f.fecha,
                                                        'DD/MM/YYYY hh:mmA'
                                                    ).format(
                                                        'hh:mm a'
                                                    )} - ${moment(
                                                        f.fechaFin,
                                                        'DD/MM/YYYY hh:mmA'
                                                    ).format('hh:mm a')}`}
                                                </span>
                                                {f.puertas ? (
                                                    <span className="ld--puertas">
                                                        Apertura de puertas{' '}
                                                        {moment(
                                                            f.puertas,
                                                            'DD/MM/YYYY hh:mmA'
                                                        ).format('hh:mm a')}
                                                    </span>
                                                ) : null}
                                            </div>
                                            <div className="ld--acciones">
                                                <button
                                                    className={`ld--btn ${classCss} ${
                                                        classCss ===
                                                            'agotado' ||
                                                        !allowBuy ||
                                                        (Verificando &&
                                                            funcionSel &&
                                                            f.id ===
                                                                funcionSel.id)
                                                            ? 'disabled'
                                                            : ''
                                                    }`}
                                                    onClick={() =>
                                                        handleClick(f)
                                                    }
                                                    disabled={
                                                        classCss ===
                                                            'agotado' ||
                                                        !allowBuy ||
                                                        (Verificando &&
                                                            funcionSel &&
                                                            f.id ===
                                                                funcionSel.id)
                                                    }
                                                >
                                                    {Verificando &&
                                                    funcionSel &&
                                                    f.id === funcionSel.id ? (
                                                            <div
                                                                className="spinner-border spinner-border-sm"
                                                                role="status"
                                                            >
                                                                <span className="visually-hidden">
                                                                    Loading...
                                                                </span>
                                                            </div>
                                                        ) : (
                                                            <span>{textBtn}</span>
                                                        )}
                                                </button>
                                                {funciones.length > 1 && (
                                                    <a
                                                        href="#"
                                                        className="verMapa"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setFunciones(
                                                                (prev) => {
                                                                    return prev.map(
                                                                        (
                                                                            fx,
                                                                            index
                                                                        ) => {
                                                                            return {
                                                                                ...fx,
                                                                                verMapa:
                                                                                    index ===
                                                                                        idx &&
                                                                                    !fx.verMapa
                                                                            };
                                                                        }
                                                                    );
                                                                }
                                                            );
                                                        }}
                                                    >
                                                        {f.verMapa
                                                            ? 'Ocultar info y precios'
                                                            : 'Ver info y precios'}
                                                    </a>
                                                )}
                                            </div>
                                        </div>

                                        {funciones.length === 1 && (
                                            <div className="ld-det-mapa-prec">
                                                <div className="row">
                                                    {(f.mapa || f.iframe) && (
                                                        <div className="col col-12 col-xl-7">
                                                            {f.mapa ? (
                                                                <img
                                                                    src={
                                                                        process
                                                                            .env
                                                                            .REACT_APP_URL_IMAGES +
                                                                        f.mapa
                                                                    }
                                                                    alt=""
                                                                    style={{
                                                                        maxWidth: 600
                                                                    }}
                                                                />
                                                            ) : f.iframe ? (
                                                                <Map
                                                                    src={
                                                                        f.iframe
                                                                    }
                                                                />
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                    )}
                                                    <div
                                                        className={`col col-12 ${
                                                            f.mapa || f.iframe
                                                                ? 'col-xl-5'
                                                                : ''
                                                        }`}
                                                    >
                                                        {
                                                            <Precios
                                                                precios={
                                                                    f.precios
                                                                }
                                                                fees={false}
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {funciones.length > 1 && f.verMapa && (
                                            <div className="ld-det-mapa-prec">
                                                <div className="row">
                                                    {(f.mapa || f.iframe) && (
                                                        <div className="col col-12 col-xl-7">
                                                            {f.mapa ? (
                                                                <img
                                                                    src={
                                                                        process
                                                                            .env
                                                                            .REACT_APP_URL_IMAGES +
                                                                        f.mapa
                                                                    }
                                                                    alt=""
                                                                    style={{
                                                                        maxWidth: 600
                                                                    }}
                                                                />
                                                            ) : f.iframe ? (
                                                                <Map
                                                                    src={
                                                                        f.iframe
                                                                    }
                                                                />
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                    )}
                                                    <div
                                                        className={`col col-12 ${
                                                            f.mapa || f.iframe
                                                                ? 'col-xl-5'
                                                                : ''
                                                        }`}
                                                    >
                                                        {
                                                            <Precios
                                                                precios={
                                                                    f.precios
                                                                }
                                                                fees={false}
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )
                            );
                        })}
                    </div>
                    {funcionSel &&
                        funcionSel.tipo === 'mapa' &&
                        (!cola || (dataSIM && dataSIM.is_allowed)) &&
                        (funcionSel.id === 1 ? (
                            <MapaC
                                closeModal={() => {
                                    setFuncionSel(null);
                                }}
                                entradas={funcionSel.entradas[moneda]}
                                moneda={moneda}
                                host={dataSIM?.host}
                                onClick={(value) => {
                                    console.log(value);
                                }}
                            />
                        ) : (
                            <MapaV
                                closeModal={() => {
                                    setFuncionSel(null);
                                }}
                                entradas={funcionSel.entradas[moneda]}
                                moneda={moneda}
                                host={dataSIM?.host}
                                onClick={(value) => {
                                    console.log(value);
                                }}
                            />
                        ))}
                    {funcionSel && dataSIM && !dataSIM.is_allowed && (
                        <WaitingIn
                            position={dataSIM.position}
                            time={
                                dataSIM.eta_in_seconds -
                                parseInt(
                                    (new Date().getTime() -
                                        dataSIM?.clientDate) /
                                        1000
                                )
                            }
                            callback={() => refreshInfo(dataSIM.host)}
                            setMoneda={setMoneda}
                        />
                    )}
                </div>
            </section>
        </>
    );
};

export default FullFunctions;
